import React, { useState, useEffect } from 'react'

const FoodtruckGalleryTab = (props) => {
    const [newMedia, setNewMedia] = useState(null);

    const removeMedia = async (item_id) => {
        let response = await fetch('https://api.foodtrack.co.il/api/truck_media/' + item_id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        });

        if (response.ok) { // if HTTP-status is 200-299
            let json = await response.json();
            console.log('json', json);

        } else {
        alert("HTTP-Error: " + response.status);
        }
    }

    const handleNewMedia = (event) => {
        setNewMedia(event.target.files[0]);
    };

    const addMedia = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', newMedia);
        fetch('https://api.foodtrack.co.il/media', {
            method: 'POST',
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            let response = fetch('https://api.foodtrack.co.il/api/truck_media', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify({
                    file_url: data.filename,
                    truck_id: props.truck.ID
                })
            });

            if (response.ok) { 

            } else {
                alert("HTTP-Error: " + response.status);
            }
          })
          .catch(error => {
            console.error(error);
          });
    }

    return(
        <div
            id="profile-gallery"
            className={`tab-pane fade ${
            props.activeToggle === "gallery" ? "active show" : ""}`}
        >
            <div className="pt-3 row">
                {props.media.map((item, index) => {
                    return(
                        <div className="col-sm-4 text-center" key={index}>
                            <button className='no-btn remove-media' onClick={() => {
                                removeMedia(item.ID);
                            }}>
                                <i className="fa fa-remove"></i>
                            </button>
                            <img key={index} src={item.file_url} />
                            {item.name && <p>{item.name}</p>}
                        </div>
                    );
                })}
            </div>

            <h4 className='text-danger mt-5'>הוספת תמונה לגלריה</h4>
            
            <form onSubmit={(e) => addMedia(e)}>
                <input type="file" onChange={handleNewMedia} />
                <button type="submit">Upload File</button>
            </form>
        </div>
    );
}

export default FoodtruckGalleryTab;

