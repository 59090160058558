import React, { createContext, useState} from "react";
const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [userId, setUserID] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [name, setName] = useState("");

    return (
        <UserContext.Provider value={{
            userId: [userId, setUserID],
            name: [name, setName],
            photo: [photo, setPhoto],
        }}>
        {children}
      </UserContext.Provider>
    );
};

const withUser = (Child) => (props) => (
    <UserContext.Consumer>
      {(context) => <Child {...props} {...context} />}
      {/* Another option is:  {context => <Child {...props} context={context}/>}*/}
    </UserContext.Consumer>
);

  export { UserProvider, withUser, UserContext };
