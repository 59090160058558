import React, { useState, useContext, useEffect } from "react";
import { 
    BrowserRouter as Router, 
    Switch, 
    Route
} from "react-router-dom";

import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import Home from "./pages/home";
import Trucks from "./pages/trucks";
import SingleFoodtruck from './pages/trucks/single';
import Events from "./pages/events";
import NewEvent from "./pages/events/NewEvent";
import Profile from './pages/profile';

/// Widget
import Widget from "./pages/Widget";

import SocialNetworkCampaign from "./components/Dashboard/SocialNetworkCampaign";
import Spendings from "./components/Dashboard/Spendings";
import Analytics from "./components/Dashboard/Analytics";
import NewCompaign from "./components/Dashboard/NewCompaign";

/// Bo
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";

import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Chirt
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";

import BtcChart from "./components/charts/apexcharts/ApexChart";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import ApexChart from "./components/charts/apexcharts";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pulgin
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import RechartJs from "./components/charts/rechart";
import ProfileSidebar from "./layouts/ProfileSidebar";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

import {UserContext} from '../contexts/user';

const Markup = ({ showProfileSideBar }) => {
    const user = useContext(UserContext);
    const [userId] = user.userId;
    const [showProfile, setShowProfile] = useState(false);
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        if( userId ) {
            setRoutes([
                /// Deshborad
                { url: "", component: Home },
                { url: "trucks", component: Trucks },
                { url: "trucks/:id", component: SingleFoodtruck},
                { url: "social-network-campaign", component: SocialNetworkCampaign }, // Trucks
                { url: "spendings", component: Spendings },
                { url: "analytics", component: Analytics },
                { url: "new-compaign", component: NewCompaign }, // New Truck
                { url: "profile", component: Profile }, // New Truck
                /// Bootstrap
                { url: "ui-alert", component: UiAlert },
                { url: "ui-badge", component: UiBadge },
                { url: "ui-button", component: UiButton },
                { url: "ui-modal", component: UiModal },
                { url: "ui-button-group", component: UiButtonGroup },
                { url: "ui-accordion", component: UiAccordion },
                { url: "ui-list-group", component: UiListGroup },
                { url: "ui-media-object", component: UiMediaObject },
                { url: "ui-card", component: UiCards },
                { url: "ui-carousel", component: UiCarousel },
                { url: "ui-dropdown", component: UiDropDown },
                { url: "ui-popover", component: UiPopOver },
                { url: "ui-progressbar", component: UiProgressBar },
                { url: "ui-tab", component: UiTab },
                { url: "ui-pagination", component: UiPagination },
                { url: "ui-typography", component: UiTypography },
                { url: "ui-grid", component: UiGrid },
                /// Apps
                { url: "app_profile", component: AppProfile },
                { url: "email-compose", component: Compose },
                { url: "email-inbox", component: Inbox },
                { url: "email-read", component: Read },
                { url: "events", component: Events },
                { url: "new-event", component: NewEvent },
                { url: "post-details", component: PostDetails },
          
                /// Chart
                { url: "chart-sparkline", component: SparklineChart },
                { url: "chart-chartjs", component: ChartJs },
                { url: "chart-chartist", component: Chartist },
                { url: "chart-btc", component: BtcChart },
                { url: "chart-apexchart", component: ApexChart },
                { url: "chart-rechart", component: RechartJs },
          
                /// table
                { url: "table-datatable-basic", component: DataTable },
                { url: "table-bootstrap-basic", component: BootstrapTable },
          
                /// Form
                { url: "form-element", component: Element },
                { url: "form-wizard", component: Wizard },
                { url: "form-wizard", component: Wizard },
                { url: "form-editor-summernote", component: SummerNote },
                { url: "form-pickers", component: Pickers },
                { url: "form-validation-jquery", component: jQueryValidation },
          
                /// Plugin
          
                { url: "uc-select2", component: Select2 },
                { url: "uc-nestable", component: Nestable },
                { url: "uc-noui-slider", component: MainNouiSlider },
                { url: "uc-sweetalert", component: MainSweetAlert },
                { url: "uc-toastr", component: Toastr },
                { url: "map-jqvmap", component: JqvMap },
                 { url: "uc-lightgallery", component: Lightgallery },
          
                /// pages
                { url: "widget-basic", component: Widget },
          
                { url: "page-register", component: Registration },
                { url: "page-lock-screen", component: LockScreen },
                { url: "page-login", component: Login },
                { url: "page-forgot-password", component: ForgotPassword },
                { url: "page-error-400", component: Error400 },
                { url: "page-error-403", component: Error403 },
                { url: "page-error-404", component: Error404 },
                { url: "page-error-500", component: Error500 },
                { url: "page-error-503", component: Error503 },
             ]);
        } else {
            setRoutes([
                { url: "register", component: Registration },
                { url: "locked", component: LockScreen },
                { url: "", component: Login },
                { url: "forgot-password", component: ForgotPassword },
                { url: "error-400", component: Error400 },
                { url: "error-403", component: Error403 },
                { url: "error-404", component: Error404 },
                { url: "error-500", component: Error500 },
                { url: "error-503", component: Error503 },
             ]);
        }
    }, [userId]);
    
   let path = window.location.pathname;
   path = path.split("/");
   path = path[path.length - 1];
   let pagePath = path.split("-").includes("page");
   return (
      <Router basename="/">
         <div
            id={`${!pagePath ? "main-wrapper" : ""}`}
            className={`${!pagePath ? "show" : "mh100vh"} ${
               !path ? "right-profile" : ""
            }`}
         >
            {!pagePath && userId && (
               <Nav
                  showProfileSideBar={showProfileSideBar}
                  showProfile={() => setShowProfile(true)}
               />
            )}
            {!path && !pagePath && userId && (
               <ProfileSidebar
                  addClass={showProfile ? "active" : ""}
                  hideProfile={() => setShowProfile(false)}
               />
            )}
            <div
               className={`  ${!pagePath ? "content-body" : ""}`}
              
            >
               <div className={`${!pagePath ? "container-fluid" : ""}`}>
                  <Switch>
                    
                     {routes.map((data, i) => (
                        <Route
                           key={i}
                           exact
                           path={`/${data.url}`}
                           component={data.component}
                        />
                     ))}
                     <Route path='*' exact={true} component={Login} />
                  </Switch>
               </div>
            </div>
            {!pagePath && <Footer />}
         </div>
      </Router>
   );
};

export default Markup;
