import React, { Component } from "react";
import { Link } from "react-router-dom";
import MetisMenu from "metismenujs";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
//    componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
//    }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      document.querySelector(".nav-control").addEventListener("click", () => {
        document.querySelector("#main-wrapper").classList.toggle("menu-toggle");
      });
   }
   render() {
      var path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let home = [ "" ],
         trucks = [ "trucks", "trucks/add" ],
         events = [ "events", "events/add" ],
         feed = [ "feed", "add-post" ],
         board = [ "board", "board/add" ],
         profile = [ "profile", "profile/edit" ];
      return (
         <div className="deznav">
            <MM className="metismenu" id="menu">
                <li className={`${home.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow ai-icon" to="/" aria-expanded="false">
                    <i className="mdi mdi-home-heart"></i>
                    <span className="nav-text">Dashboard</span>
                    </Link>
                </li>
                <li className={`${events.includes(path) ? "mm-active" : ""}`}>
                    <Link
                    className="has-arrow ai-icon"
                    to="/events"
                    aria-expanded="false"
                    >
                    <i className="mdi mdi-calendar"></i>
                    <span className="nav-text">Events</span>
                    </Link>
                </li>
                <li className={`${trucks.includes(path) ? "mm-active" : ""}`}>
                    <Link
                    className="has-arrow ai-icon"
                    to="/trucks"
                    aria-expanded="false"
                    >
                    <i className="mdi mdi-truck"></i>
                    <span className="nav-text">Trucks</span>
                    </Link>
                </li>
                <li className={`${board.includes(path) ? "mm-active" : ""}`}>
                    <Link
                    className="has-arrow ai-icon"
                    to="/board"
                    aria-expanded="false"
                    >
                    <i className="mdi mdi-bulletin-board"></i>
                    <span className="nav-text">Board</span>
                    </Link>
                </li>
                <li className={`${profile.includes(path) ? "mm-active" : ""}`}>
                    <Link
                    className="has-arrow ai-icon"
                    to="/profile"
                    aria-expanded="false"
                    >
                    <i className="mdi mdi-face-profile"></i>
                    <span className="nav-text">Profile</span>
                    </Link>
                </li>
            </MM>
         </div>
      );
   }
}

export default SideBar;
