import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Alert from "sweetalert2";
import heLocale from '@fullcalendar/core/locales/he';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

const EventCalendar = (props) => {
   const eventClick = (eventClick) => {
    let d = new Date(eventClick.event.start);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('he', { month: 'long' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      Alert.fire({
         title: eventClick.event.title,
         html:
            `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr >
      <td>מיקום</td>
      <td><strong>` +
            eventClick.event.location +
            `</strong></td>
      </tr>
      <tr >
      <td>מתחיל ב</td>
      <td><strong>
      ` + da + ` ב` + mo + `, ` + ye + `
      </strong></td>
      </tr>
      <tr >
      <td>בשעה</td>
      <td><strong>
      ` +
            eventClick.event.start_time + 
            `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

         showCancelButton: false,
         confirmButtonColor: "#E15F65",
         cancelButtonColor: "#3085d6",
         confirmButtonText: "הסתר",
        //  cancelButtonText: "Close",
      }).then((result) => {
        //  if (result.value) {
            // eventClick.event.remove(); // It will remove event from the calendar
            // Alert.fire("Deleted!", "Your Event has been deleted.", "success");
        //  }
      });
   };
   return (
        <div className="card">
        <div className="card-body">
        <div className="demo-app-calendar" id="mycalendartest">
            <FullCalendar
                locale={heLocale}
                defaultView="dayGridMonth"
                header={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                }}
                rerenderDelay={10}
                eventDurationEditable={false}
                editable={false}
                droppable={false}
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                ]}
                // ref={this.calendarComponentRef}
                // weekends={calendarWeekends}
                events={props.events}
                // eventDrop={this.drop}
                // drop={this.drop}
                // eventReceive={eventReceive}
                eventClick={eventClick}
                // selectable={true}
            />
        </div>
        </div>
    </div>
    );
}

export default EventCalendar;
