import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import FoodtruckHeading from './components/heading';
import FoodtruckSidebar from './components/sidebar';
import FoodtruckPostsTab from './components/tab-posts';
import FoodtruckReviewsTab from "./components/tab-reviews";
import FoodtruckEditTab from './components/tab-edit';
import FoodtruckManagerTab from './components/tab-manager';
import FoodtruckGalleryTab from './components/tab-gallery';
// import { SRLWrapper } from "simple-react-lightbox";

const SingleFoodtruck = () => {
   const [activeToggle, setActiveToggle] = useState("posts");
   const [media, setMedia] = useState([]);
    const [truck, setTruck] = useState(null);
   
   const params = useParams();


   const getMedia = async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/truck_media?truck_id=' + params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },
        });

        if (response.ok) {
            let serverTruck = await response.json();
            setMedia( serverTruck );
        } else {
            alert("HTTP-Error: " + response.status);
        }
   }
    useEffect( async() => {
        getMedia();
        let response = await fetch('https://api.foodtrack.co.il/api/trucks/' + params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },
        });

        if (response.ok) {
            let serverTruck = await response.json();
            console.log( serverTruck );
            setTruck( serverTruck );

        } else {
            alert("HTTP-Error: " + response.status);
        }
    }, [params.id]);

   
    return (
        <Fragment>
            {truck && <FoodtruckHeading truck={truck} />}
            {truck &&
            <div className="row">
                <FoodtruckSidebar truck={truck} media={media} />
                <div className="col-xl-8">
                <div className="card">
                    <div className="card-body">
                        <div className="profile-tab">
                            <div className="custom-tab-1">
                            <ul className="nav nav-tabs">
                                <li className="nav-item"
                                    onClick={() => setActiveToggle("posts")}>
                                    <Link
                                        to="#my-posts"
                                        data-toggle="tab"
                                        className={`nav-link ${activeToggle === "posts" ? "active show" : "" }`}
                                    >פוסטים</Link>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => setActiveToggle("aboutMe")}
                                >
                                    <Link
                                        to="#about-me"
                                        data-toggle="tab"
                                        className={`nav-link ${
                                        activeToggle === "aboutMe" ? "active show" : "" }`}
                                    >ביקורות</Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="#profile-settings"
                                        data-toggle="tab"
                                        onClick={() => setActiveToggle("setting")}
                                        className={`nav-link ${
                                        activeToggle === "setting" ? "active show" : "" }`}
                                    >עריכה</Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="#profile-gallery"
                                        data-toggle="tab"
                                        onClick={() => setActiveToggle("gallery")}
                                        className={`nav-link ${
                                        activeToggle === "gallery" ? "active show" : "" }`}
                                    >גלריה</Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="#manager-settings"
                                        data-toggle="tab"
                                        onClick={() => setActiveToggle("msetting")}
                                        className={`nav-link ${
                                        activeToggle === "msetting" ? "active show" : "" }`}
                                    >עריכת מנהל</Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                    <FoodtruckPostsTab truck={truck} activeToggle={activeToggle} />
                                    <FoodtruckReviewsTab truck={truck} activeToggle={activeToggle} />
                                    <FoodtruckGalleryTab truck={truck} media={media} activeToggle={activeToggle} />
                                    <FoodtruckEditTab truck={truck} activeToggle={activeToggle} />
                                    <FoodtruckManagerTab truck={truck} activeToggle={activeToggle} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            }
        </Fragment>
    );
};

export default SingleFoodtruck;
