import React, { useState, useEffect } from 'react'
import { Dropdown } from "react-bootstrap";

const FoodtruckManagerTab = (props) => {
    const [memberOwner, setMemberOwner] = useState(0);
    const [memberName, setMemberName] = useState('לא הוגדר');
    const [members, setMembers] = useState([]);

    const getMembers = async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/users/');

        if (response.ok) {
            let membersArray = await response.json();
            setMembers(membersArray);
            if( props.truck.customer && props.truck.customer !== 0 ){
                setMemberOwner(props.truck.customer);
                if( membersArray.length > 0 ){
                    for(let member of membersArray){
                        if(member.ID === props.truck.customer)
                            setMemberName(member.name);
                    }
                }
                
            }
        } else {
            alert("HTTP-Error: " + response.status);
        }
    }

    useEffect(() => {
        getMembers();
        
    }, [])

    const updateMember = async (item) => {
        
        let response = await fetch('https://api.foodtrack.co.il/api/trucks/member/' + props.truck.ID, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ customer: item !== 0 ? item.ID : 0 })
        });

        if (response.ok) { // if HTTP-status is 200-299
            let json = await response.json();
            setMemberOwner(item !== 0 ? item.ID : 0);
            setMemberName(item !== 0 ? item.name : 'לא הוגדר');

        } else {
        alert("HTTP-Error: " + response.status);
        }
    }

    return(
        <div
            id="manager-settings"
            className={`tab-pane fade ${
            props.activeToggle === "msetting" ? "active show" : ""}`}
        >
            <div className="pt-3">
                <div className="settings-form">
                    <h4 className="text-primary">עריכת מנהל</h4>

                    <Dropdown className="dropdown ml-auto">
                        <Dropdown.Toggle
                            variant="primary"
                            className={'btn btn-default light sharp icon-false'}
                            data-toggle="dropdown"
                            aria-expanded="true"
                        >
                            {memberName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                            <Dropdown.Item className="dropdown-item" onClick={() => updateMember(0)}>{'ללא מנהל'}</Dropdown.Item>
                            {members.map((item, index) => <Dropdown.Item key={index} className="dropdown-item" onClick={() => updateMember(item)}>{item.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>                    
                </div>
            </div>
        </div>
    );
}

export default FoodtruckManagerTab;


/*
<div className="form-row">
                            <div className="form-group col-md-6">
                            <label>City</label>
                            <input
                                type="text"
                                className="form-control"
                            />
                            </div>
                            <div className="form-group col-md-4">
                            <label>State</label>
                            <select
                                className="form-control"
                                id="inputState"
                                defaultValue="option-1"
                            >
                                <option value="option-1">
                                    Choose...
                                </option>
                                <option value="option-2">
                                    Option 1
                                </option>
                                <option value="option-3">
                                    Option 2
                                </option>
                                <option value="option-4">
                                    Option 3
                                </option>
                            </select>
                            </div>
                            <div className="form-group col-md-2">
                            <label>Zip</label>
                            <input
                                type="text"
                                className="form-control"
                            />
                            </div>
                        </div> */

