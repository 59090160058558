import React, { useState, useEffect } from 'react'

const FoodtruckEditTab = (props) => {
    const [name, setName] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [coordinates, setCoordinates] = useState('');
    const [overview, setOverview] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [location_name, setLocation_name] = useState('');
    const [directions, setDirections] = useState('');
    const [notification_title, setNotification_title] = useState('');
    const [notification_subtitle, setNotification_subtitle] = useState('');
    const [accessiblity, setAccessiblity] = useState('');
    const [kosher, setKosher] = useState('');
    const [saved, setSaved] = useState(false);
    const [truckFoodtypes, setTruckFoodtypes] = useState([]);

    const [foodtypes, setFoodtypes] = useState([]);

    const [logo, setLogo] = useState(null);

    const handleLogoChange = (event) => {
        setLogo(event.target.files[0]);
    };

    const updateLogo = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', logo);
        fetch('https://api.foodtrack.co.il/media', {
            method: 'POST',
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            let response = fetch('https://api.foodtrack.co.il/api/trucks/logo/' + props.truck.ID, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify({
                    logo: data.filename,
                })
            });

            if (response.ok) { 

            } else {
            alert("HTTP-Error: " + response.status);
            }
          })
          .catch(error => {
            console.error(error);
          });
    };

    const getFoodtypes = async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/foodtypes/');

        if (response.ok) {
            let foodtypesArray = await response.json();
            setFoodtypes(foodtypesArray);
        } else {
            alert("HTTP-Error: " + response.status);
        }
    }

    useEffect(() => {
        getFoodtypes();
        if( props.truck.foodtypes )
            setTruckFoodtypes(props.truck.foodtypes.split(','));
        setName(props.truck.name);
        setSubtitle(props.truck.subtitle);
        setWebsite(props.truck.website);
        setFacebook(props.truck.facebook);
        setCoordinates(props.truck.coordinates);
        setOverview(props.truck.overview);
        setEmail(props.truck.email);
        setPhone(props.truck.phone);
        setLocation_name(props.truck.location_name);
        setDirections(props.truck.directions);
        setNotification_title(props.truck.notification_title);
        setNotification_subtitle(props.truck.notification_subtitle);
        setAccessiblity(props.truck.accessiblity);
        setKosher(props.truck.kosher);
    }, [])

    const update = async (e) => {
        e.preventDefault();

        let response = await fetch('https://api.foodtrack.co.il/api/trucks/' + props.truck.ID, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({
                name: name,
                subtitle: subtitle,
                directions: directions,
                location_name: location_name,
                coordinates: coordinates,
                overview: overview,
                website: website,
                facebook: facebook,
                accessiblity: accessiblity,
                kosher: kosher,
                notification_title: notification_title,
                notification_subtitle: notification_subtitle,
                email: email,
                phone: phone,
            })
        });

        if (response.ok) { // if HTTP-status is 200-299
            let json = await response.json();
            console.log('json', json);
            setSaved(true);
            setInterval(() => {
                setSaved(false);
            }, 5000);
            if( json ){
                
            }

        } else {
        alert("HTTP-Error: " + response.status);
        }
    }
    const updateFoodtypes = async (e) => {
        e.preventDefault();

        let response = await fetch('https://api.foodtrack.co.il/api/trucks/foodtypes/' + props.truck.ID, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({
                foodtypes: truckFoodtypes.join(','),
            })
        });

        if (response.ok) { // if HTTP-status is 200-299
            let json = await response.json();
            // setSaved(true);

        } else {
        alert("HTTP-Error: " + response.status);
        }
    }

    return(
        <div
            id="profile-settings"
            className={`tab-pane fade ${
            props.activeToggle === "setting" ? "active show" : ""}`}
        >
            <div className="pt-3">
                <div className="settings-form">
                    <h4 className="text-danger">בחירת סוגי אוכל</h4>
                    <form onSubmit={(e) => updateFoodtypes(e)}>
                        <div className="row">
                        {foodtypes.map((item, index) => {
                            return(
                                <FoodtypeCheckbox 
                                    key={index} 
                                    item={item} 
                                    truckFoodtypes={truckFoodtypes}
                                    setTruckFoodtypes={setTruckFoodtypes}
                                />
                            );
                        })}
                        </div>
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >עדכון</button>
                    </form>
                    <h4 className="text-danger mt-5">עריכת פודטראק</h4>
                    {saved && <div className='alert alert-success'>נשמר בהצלחה!</div>}
                    <form onSubmit={(e) => update(e)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <label>שם</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            </div>
                            <div className="form-group col-md-6">
                            <label>סלוגן</label>
                            <input
                                type="text"
                                name="subtitle"
                                className="form-control"
                                value={subtitle}
                                onChange={(e) => setSubtitle(e.target.value)}
                                required
                            />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <label>טלפון</label>
                            <input
                                type="tel"
                                name="phone"
                                className="form-control"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            </div>
                            <div className="form-group col-md-6">
                            <label>אימייל</label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>שם הישוב / המקום</label>
                                <input
                                    type="text"
                                    name="location_name"
                                    className="form-control"
                                    value={location_name}
                                    onChange={(e) => setLocation_name(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label>קורדינטות</label>
                                <input
                                    type="text"
                                    name="coordinates"
                                    className="form-control"
                                    value={coordinates}
                                    onChange={(e) => setCoordinates(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>איך להגיע?</label>
                            <input
                            type="text"
                            name="directions"
                            className="form-control"
                            value={directions}
                            onChange={(e) => setDirections(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>סיקור / פרטים מלאים</label>
                            <textarea
                            name="overview"
                            className="form-control"
                            onChange={(e) => setOverview(e.target.value)}
                            required
                            value={overview}
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <label>כותרת נוטיפיקציה</label>
                            <input
                                type="text"
                                name="notification_title"
                                className="form-control"
                                value={notification_title}
                                onChange={(e) => setNotification_title(e.target.value)}
                            />
                            </div>
                            <div className="form-group col-md-6">
                            <label>כותרת משנית נוטיפקציה</label>
                            <input
                                type="text"
                                name="notification_subtitle"
                                className="form-control"
                                value={notification_subtitle}
                                onChange={(e) => setNotification_subtitle(e.target.value)}
                            />
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="accessiblity"
                                    name="accessiblity"
                                    checked={accessiblity}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setAccessiblity(!accessiblity)
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="accessiblity"
                                >
                                עסק נגיש לבעלי מוגבלויות
                            </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="kosher"
                                    name="kosher"
                                    checked={kosher}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setKosher(!kosher)
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="accessiblity"
                                >
                                עסק כשר
                            </label>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <label>אתר אינטרנט</label>
                            <input
                                type="text"
                                name="website"
                                className="form-control"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                            />
                            </div>
                            <div className="form-group col-md-6">
                            <label>פייסבוק</label>
                            <input
                                type="text"
                                name="facebook"
                                className="form-control"
                                value={facebook}
                                onChange={(e) => setFacebook(e.target.value)}
                            />
                            </div>
                        </div> 
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >
                            עדכון
                        </button>
                    </form>

                    <h4 className='text-danger mt-5'>תמונת לוגו</h4>
                    {props.truck.logo &&
                    <img src={props.truck.logo} className="edit-logo" />
                    }
                    <form onSubmit={(e) => updateLogo(e)}>
                        <input type="file" onChange={handleLogoChange} />
                        <button type="submit">Upload File</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FoodtruckEditTab;

const FoodtypeCheckbox = (props) => {
    const itemID = props.item.ID.toString();
    const [checked, setChecked] = useState( false );

    useEffect(() => {
        setChecked( props.truckFoodtypes.includes(itemID) );
    }, [])

    
    return(
        <div className="form-group col-sm-4">
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={itemID}
                    name={itemID}
                    checked={props.truckFoodtypes.includes(itemID)}
                    onChange={(e) => {
                        e.preventDefault();
                        let curTruckFoodtypes = props.truckFoodtypes;
                        if( checked ){
                            let index = curTruckFoodtypes.indexOf(itemID);
                            if (index > -1) {
                                curTruckFoodtypes.splice(index, 1);
                            }
                        } else {
                            curTruckFoodtypes.push(itemID);
                        }
                        setChecked(!checked);
                        props.setTruckFoodtypes(curTruckFoodtypes);
                        
                    }}
                />
                <label
                    className="custom-control-label"
                    htmlFor={itemID}
                >{props.item.name}</label>
            </div>
        </div>
    );
}
