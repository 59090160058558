import React, { useState } from "react";
import { Link } from "react-router-dom";
import SnappingTOValuesDeshboard from "./SnappingToValues";
import CustomClearIndicator from "./MultiSelect";

function NewEvent() {
    const [name, setName] = useState('');
    const [start_date, setStart_date] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [start_time, setStart_time] = useState('');
    const [end_time, setEnd_time] = useState('');
    const [location, setLocation] = useState('');
    const [host, setHost] = useState('');
    const [overview, setOverview] = useState('');
    const [disabled, setDisabled] = useState(true);

    const sendEvent = async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/events', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({title: name, host: host, location: location, start_date: start_date, end_date: end_date, start_time: start_time, end_time: end_time, overview: overview })
             
        });

        if (response.ok) { // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await response.json();
            console.log('json', json);

            if( json ){
                setName( '' );
                setLocation( '' );
                setOverview( '' );
                setHost( '' );
                setStart_date( '' );
                setEnd_date( '' );
                setStart_time( '' );
                setEnd_time( '' );
            }

        } else {
        alert("HTTP-Error: " + response.status);
        }
    }

    const handleField = (key, value) => {
        if( key === 'name' )
            setName(value);
        else if( key === 'host' )
            setHost(value);
        else if( key === 'location' )
            setLocation(value);
        else if( key === 'start_date' )
            setStart_date(value);
        else if( key === 'end_date' )
            setEnd_date(value);
        else if( key === 'start_time' )
            setStart_time(value);
        else if( key === 'end_time' )
            setEnd_time(value);
        else if( key === 'overview' )
            setOverview(value);
        
        if(
            name.length > 2  &&
            host.length > 2 &&
            location.length > 2 &&
            overview.length > 10 &&
            start_date.length > 2 &&
            end_date.length > 2 &&
            start_time.length > 2 &&
            end_time.length > 2
        ){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    return (
      <>
         <div className="form-head d-flex mb-2 mb-sm-3  align-items-center">
            <div className="mr-auto d-none d-lg-block">
               <h2 className="text-black font-w600">אירוע חדש</h2>
            </div>
            <div>
               <Link to="/new-compaign" className="btn btn-outline-primary">
                  תצוגה מקדימה
               </Link>
               <button type="button" disabled={disabled} onClick={sendEvent} className="btn btn-primary ml-3">
                  פרסום
               </button>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-9 col-xxl-8 col-lg-8">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="card">
                        <div className="card-header pb-0 border-0">
                           <h3 className="fs-20 text-black">
                              תיאור האירוע
                           </h3>
                        </div>
                        <div className="card-body">
                           <form>
                              <div className="form-row">
                                 <div className="form-group col-xl-12">
                                    <label className="text-black font-w500 mb-3">
                                       שם האירוע
                                    </label>
                                    <span className="text-danger ml-1">*</span>
                                    <input
                                       type="text"
                                       className="form-control"
                                       value={name}
                                       onChange={(e) => handleField('name', e.target.value)}
                                    />
                                 </div>
                                 <div className="form-group col-xl-6">
                                    <label className="text-black font-w500 mb-3">
                                       מיקום האירוע
                                    </label>
                                    <span className="text-danger ml-1">*</span>
                                    <input
                                       type="text"
                                       className="form-control"
                                       value={location}
                                       onChange={(e) => handleField('location', e.target.value)}
                                    />
                                 </div>
                                 <div className="form-group col-xl-6">
                                    <label className="text-black font-w500 mb-3">
                                       מארח / הפקה
                                    </label>
                                    <span className="text-danger ml-1">*</span>
                                    <input
                                       type="text"
                                       className="form-control"
                                       value={host}
                                       onChange={(e) => handleField('host', e.target.value)}
                                    />
                                 </div>
                                 <div className="form-group col-xl-12">
                                    <label className="text-black font-w500 mb-3">
                                       תיאור מלא
                                    </label>
                                    <span className="text-danger ml-1">*</span>
                                    <textarea
                                       className="form-control"
                                       rows={13}
                                       onChange={(e) => handleField('overview', e.target.value)}
                                    >{overview}</textarea>
                                 </div>
                                 <div
                                    className="form-group col-xl-12"
                                    style={{
                                       position: "relative",
                                       zIndex: "2",
                                    }}
                                 >
                                    <label className="text-black font-w500 mb-3">
                                       סוג האירוע
                                    </label>
                                    <span className="text-danger ml-1">*</span>

                                    <CustomClearIndicator />
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="card">
                        <div className="card-body pb-0">
                           <div className="row">
                              <div className="col-xl-6">
                                 <div className="form-group col-xl-12 p-0">
                                    <label className="text-black font-w500 mb-3">
                                       תאריך התחלה
                                    </label>
                                    <input
                                       type="date"
                                       className="form-control"
                                       value={start_date}
                                       onChange={(e) => handleField('start_date', e.target.value)}
                                    />
                                 </div>
                              </div>
                              <div className="col-xl-6">
                                 <div className="form-group col-xl-12 p-0">
                                    <label className="text-black font-w500 mb-3">
                                       תאריך סיום
                                    </label>
                                    <input
                                       type="date"
                                       className="form-control"
                                       value={end_date}
                                       onChange={(e) => handleField('end_date', e.target.value)}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="row">
                              <div className="col-xl-6">
                                 <div className="form-group col-xl-12 p-0">
                                    <label className="text-black font-w500 mb-3">
                                       שעת התחלה
                                    </label>
                                    <input
                                       type="time"
                                       className="form-control"
                                       value={start_time}
                                       onChange={(e) => handleField('start_time', e.target.value)}
                                    />
                                 </div>
                              </div>
                              <div className="col-xl-6">
                                 <div className="form-group col-xl-12 p-0">
                                    <label className="text-black font-w500 mb-3">
                                       שעת סיום
                                    </label>
                                    <input
                                       type="time"
                                       className="form-control"
                                       value={end_time}
                                       onChange={(e) => handleField('end_time', e.target.value)}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-3 col-xxl-4 col-lg-4">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="card">
                        <div className="card-header border-0 pb-0">
                           <h3 className="text-black fs-20">תמונות</h3>
                           <Link
                              className="btn btn-link fs-18 pr-0"
                              to="/new-compaign"
                           >
                              + בחירת תמונות
                           </Link>
                        </div>
                        <div className="card-body">
                           <div className="bgl-primary p-3 text-black rounded">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="card">
                        <div className="card-header border-0 pb-0">
                           <h3 className="text-black fs-20">
                              Social Media Stream
                           </h3>
                           <Link
                              className="btn btn-link text-dark fs-18 pr-0"
                              to="/new-compaign"
                           >
                              Clear
                           </Link>
                        </div>
                        <div className="card-body">
                           <div className="d-flex mb-3 align-items-center justify-content-between">
                              <div className="d-flex">
                                 <div className="custom-control custom-checkbox mr-2">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="customCheckBox7"
                                       required
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="customCheckBox7"
                                    />
                                 </div>
                                 <span className="text-black font-w600">
                                    Instagram
                                 </span>
                              </div>
                           </div>
                           <div className="d-flex mb-3 align-items-center justify-content-between">
                              <div className="d-flex">
                                 <div className="custom-control custom-checkbox mr-2">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="customCheckBox71"
                                       required
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="customCheckBox71"
                                    />
                                 </div>
                                 <span className="text-black font-w600">
                                    Facebook
                                 </span>
                              </div>
                           </div>
                           <div className="d-flex mb-3 align-items-center justify-content-between">
                              <div className="d-flex">
                                 <div className="custom-control custom-checkbox mr-2">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="customCheckBox72"
                                       required
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="customCheckBox72"
                                    />
                                 </div>
                                 <span className="text-black font-w600">
                                    Twitter
                                 </span>
                              </div>
                           </div>
                           <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex">
                                 <div className="custom-control custom-checkbox mr-2">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="customCheckBox73"
                                       required
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="customCheckBox73"
                                    />
                                 </div>
                                 <span className="text-black font-w600">
                                    Youtube
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default NewEvent;
