import React, { useState }  from 'react'
import { Dropdown } from "react-bootstrap";



const FoodtruckHeading = (props) => {
    const [status, setStatus] = useState(props.truck.status);
    let textualStatus = "";
    let statusClass = "btn btn-default light sharp icon-false";

    if( status === 'running'){
        textualStatus = "פעיל";
        statusClass = "btn btn-danger light sharp icon-false";
    } else if( status === 'waiting' )
        textualStatus = "ממתין";
    else if( status === 'trouble' )
        textualStatus = "בבעיה";
    else if(status === 'suspended' )
        textualStatus = "מושהה";
    else if(status === 'temp_closed' )
        textualStatus = "זמנית סגור";

    const updateStatus = async (newVal) => {
        let response = await fetch('https://api.foodtrack.co.il/api/trucks/status/' + props.truck.ID, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ status: newVal })
        });

        if (response.ok) {
            let json = await response.json();
            console.log('json', json);
            setStatus(newVal);
            // if( json ){
            // }

        } else {
            alert("HTTP-Error: " + response.status);
        }
    }
    const logo = props.truck.logo.startsWith('https://api.foodtrack.co.il') ? props.truck.logo : 'https://api.foodtrack.co.il/media/' + props.truck.logo;
    return(
        <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="photo-content">
                        <div className="cover-photo"></div>
                     </div>
                     <div className="profile-info">
                        <div className="profile-photo">
                           <img
                              src={logo}
                              className="img-fluid rounded-circle"
                              alt="profile"
                           />
                        </div>
                        <div className="profile-details">
                           <div className="profile-name px-3 pt-2">
                              <h4 className="text-danger mb-0">
                                 {props.truck.name}
                              </h4>
                              <p>{props.truck.subtitle}</p>
                           </div>
                           <div style={{flex: 1}}></div>
                           <div className="mr-2">
                           <Dropdown className="dropdown ml-auto">
                              <Dropdown.Toggle
                                 variant="primary"
                                 className={statusClass}
                                 data-toggle="dropdown"
                                 aria-expanded="true"
                              >
                                 {textualStatus}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                              {props.truck.status !== 'temp_closed' &&
                                 <Dropdown.Item className="dropdown-item" onClick={() => updateStatus('temp_closed')}>
                                    <i className="fa fa-lock text-primary mr-2" />
                                    זמנית סגור
                                 </Dropdown.Item>
                                 }
                                 {props.truck.status !== 'running' &&
                                 <Dropdown.Item className="dropdown-item" onClick={() => updateStatus('running')}>
                                    <i className="fa fa-check text-primary mr-2" />
                                    הפעלה
                                 </Dropdown.Item>
                                 }
                                 {props.truck.status !== 'waiting' &&
                                 <Dropdown.Item className="dropdown-item" onClick={() => updateStatus('waiting')}>
                                    <i className="fa fa-clock-o text-primary mr-2" />
                                    מעבר למצב המתנה
                                 </Dropdown.Item>
                                 }
                                 {props.truck.status !== 'trouble' &&
                                 <Dropdown.Item className="dropdown-item" onClick={() => updateStatus('trouble')}>
                                    <i className="fa fa-lock text-primary mr-2" />
                                    בבעיה
                                 </Dropdown.Item>
                                 }
                                 {props.truck.status !== 'suspended' &&
                                 <Dropdown.Item className="dropdown-item" onClick={() => updateStatus('suspended')}>
                                    <i className="fa fa-lock text-primary mr-2" />
                                    להשהות
                                 </Dropdown.Item>
                                 }
                              </Dropdown.Menu>
                           </Dropdown>
                           </div>
                           <div>
                           <Dropdown className="dropdown ml-auto">
                              <Dropdown.Toggle
                                 variant="primary"
                                 className="btn btn-primary light sharp icon-false"
                                 data-toggle="dropdown"
                                 aria-expanded="true"
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                 >
                                    <g
                                       stroke="none"
                                       strokeWidth="1"
                                       fill="none"
                                       fillRule="evenodd"
                                    >
                                       <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                       ></rect>
                                       <circle
                                          fill="#000000"
                                          cx="5"
                                          cy="12"
                                          r="2"
                                       ></circle>
                                       <circle
                                          fill="#000000"
                                          cx="12"
                                          cy="12"
                                          r="2"
                                       ></circle>
                                       <circle
                                          fill="#000000"
                                          cx="19"
                                          cy="12"
                                          r="2"
                                       ></circle>
                                    </g>
                                 </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                 <Dropdown.Item className="dropdown-item">
                                    <i className="fa fa-lock text-primary mr-2" />
                                    זמנית סגור
                                 </Dropdown.Item>
                                 <Dropdown.Item className="dropdown-item">
                                    <i className="fa fa-ban text-primary mr-2" />
                                    חסום
                                 </Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </div>
    );
}

export default FoodtruckHeading;