import React, { useEffect, useState } from "react";


const FoodtruckSidebar = (props) => {
    const [foodtypeCount, setFoodtypeCount] = useState(0);
    const [hours, setHours] = useState([]);

    const getHours = async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/truck_hour?truck_id=' + props.truck.ID, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },
        });

        if (response.ok) {
            let truckhours = await response.json();
            console.log(truckhours);
            setHours( truckhours );
        } else {
            alert("HTTP-Error: " + response.status);
        }
    }

    useEffect(() => {
        getHours();
        if( props.truck.foodtypes && props.truck.foodtypes !== '')
        setFoodtypeCount( props.truck.foodtypes.split(',').length );
    }, []);

    const created = new Date(props.truck.created).toLocaleDateString("he-IL");
    return(
        <div className="col-xl-4">
            <div className="card">
                <div className="card-body">
                    <div className="profile-statistics mb-5">
                    <div className="text-center">
                        <div className="row">
                            <div className="col">
                                <h3 className="m-b-0">{foodtypeCount}</h3>
                                <span>סוגי אוכל</span>
                            </div>
                            <div className="col">
                                <h3 className="m-b-0">{props.media.length}</h3>
                                <span>מדיה</span>
                            </div>
                            <div className="col">
                                <h3 className="m-b-0">0</h3>
                                <span>ביקורות</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="profile-blog mb-5">
                    <h5 className="d-inline">
                        מידע כללי
                    </h5>
                    <dl className="dl-horizontal">
                        <dt>שם המקום</dt>
                        <dd>{props.truck.location_name}</dd>

                        <dt>ת. הצטרפות</dt>
                        <dd>{created}</dd>

                        <dt>איך להגיע?</dt>
                        <dd>{props.truck.directions}</dd>

                        <dt>סיקור</dt>
                        <dd>{props.truck.overview}</dd>
                        {props.truck.notification_title && <div>
                            <dt>כותרת - התראה</dt>
                            <dd>{props.truck.notification_title}</dd>
                        </div>}
                        {props.truck.notification_subtitle && <div>
                            <dt>כותרת משנית - התראה</dt>
                            <dd>{props.truck.notification_subtitle}</dd>
                        </div>}
                    </dl>
                    </div>

                    <h4>שעות פתיחה</h4>
                    <dl className="dl-horizontal">
                        <dt>ראשון</dt>
                        <dd className="row">
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={1}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={1}
                                    end={true}
                                />
                            </div>
                        </dd>

                        <dt>שני</dt>
                        <dd className="row">
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={2}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={2}
                                    end={true}
                                />
                            </div>
                        </dd>

                        <dt>שלישי</dt>
                        <dd className="row">
                        <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={3}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={3}
                                    end={true}
                                />
                            </div>
                        </dd>

                        <dt>רביעי</dt>
                        <dd className="row">
                        <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={4}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={4}
                                    end={true}
                                />
                            </div>
                        </dd>

                        <dt>חמישי</dt>
                        <dd className="row">
                        <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={5}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={5}
                                    end={true}
                                />
                            </div>
                        </dd>

                        <dt>שישי</dt>
                        <dd className="row">
                        <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={6}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={6}
                                    end={true}
                                />
                            </div>
                        </dd>

                        <dt>שבת</dt>
                        <dd className="row">
                        <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={7}
                                    start={true}
                                />
                            </div>
                            <div className="col-sm-6 pl-2">
                                <FoodtruckTimeInput 
                                    hours={hours}
                                    day={7}
                                    end={true}
                                />
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
}

const FoodtruckTimeInput = (props) => {
    const [time, setTime] = useState('');
    const [hourObject, setHourObject] = useState(null);

    useEffect(() => {
        if( props.hours.length > 0 ) {
            for(let hour of props.hours){
                if( hour.day === props.day ){
                    setHourObject(hour);
                    setTime(props.start ? hour.start : hour.end);
                }
            }
        }
    }, [props.hours]);

    const updateTime = async (e) => {
        setTime(e.target.value)

        let response = await fetch('https://api.foodtrack.co.il/api/truck_hour/' + hourObject.ID, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ 
                day: hourObject.day,
                start: props.start ? e.target.value : hourObject.start,
                end: !props.start ? e.target.value : hourObject.end,
                truck: hourObject.truck,
            })
        });

        if (response.ok) { // if HTTP-status is 200-299
            let json = await response.json();
            // console.log(json);
            
        } else {
        alert("HTTP-Error: " + response.status);
        }

    }

    return(
        <input type="time" className="form-control" value={time} onChange={(e)=> updateTime(e)} />
    );
}

export default FoodtruckSidebar;