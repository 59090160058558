import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import ChartDoughnut2 from "../../components/charts/Chartjs/donught2";

function Trucks() {
    const [trucks, setTrucks] = useState([]);
    
    useEffect( async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/trucks/', {
            method: 'GET',
  headers: {
    'Content-Type': 'application/json; charset=utf-8', // set the content type and character encoding
    'Accept': 'application/json', // set the accept header to indicate that the response should be in JSON format
  },
        });

        if (response.ok) {
            let serverTrucks = await response.json();
            console.log('json', serverTrucks);

            if( serverTrucks.length > 0 ){
                setTrucks( serverTrucks );
            }

        } else {
            alert("HTTP-Error: " + response.status);
        }

    }, []);

const created = new Date().toLocaleDateString("en-US")

   return (
      <>
         <div className="form-head d-flex mb-0 mb-lg-4 align-items-start">
            <div className="mr-auto d-none d-lg-block">
               <h2 className="text-black font-w600">הפודטראק׳ס שלי</h2>
            </div>
         </div>
         <div className="row">
            {/* <div className="col-xl-12">
               <div className="d-lg-flex d-block pl-0 pr-0 border-0 align-items-end justify-content-between mb-3">
                  
                  <div>
                     <Link to="/campaign" className="btn bg-white mb-2 mr-2">
                        <svg
                           width={14}
                           height={14}
                           className="scale5"
                           viewBox="0 0 20 18"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M19 2.25H8.5C8.5 1.42275 7.82725 0.75 7 0.75H5.5C4.67275 0.75 4 1.42275 4 2.25H1C0.586 2.25 0.25 2.58525 0.25 3C0.25 3.41475 0.586 3.75 1 3.75H4C4 4.57725 4.67275 5.25 5.5 5.25H7C7.82725 5.25 8.5 4.57725 8.5 3.75H19C19.414 3.75 19.75 3.41475 19.75 3C19.75 2.58525 19.414 2.25 19 2.25ZM5.5 3.75V2.25H7L7.00075 2.997C7.00075 2.9985 7 2.99925 7 3C7 3.00075 7.00075 3.0015 7.00075 3.003V3.75H5.5Z"
                              fill="#2E2E2E"
                           />
                           <path
                              d="M19 8.25H14.5C14.5 7.42275 13.8273 6.75 13 6.75H11.5C10.6727 6.75 10 7.42275 10 8.25H1C0.586 8.25 0.25 8.58525 0.25 9C0.25 9.41475 0.586 9.75 1 9.75H10C10 10.5773 10.6727 11.25 11.5 11.25H13C13.8273 11.25 14.5 10.5773 14.5 9.75H19C19.414 9.75 19.75 9.41475 19.75 9C19.75 8.58525 19.414 8.25 19 8.25ZM11.5 9.75V8.25H13L13.0007 8.997C13.0007 8.9985 13 8.99925 13 9C13 9.00075 13.0007 9.0015 13.0007 9.003V9.75H11.5Z"
                              fill="#2E2E2E"
                           />
                           <path
                              d="M19 14.25H8.5C8.5 13.4227 7.82725 12.75 7 12.75H5.5C4.67275 12.75 4 13.4227 4 14.25H1C0.586 14.25 0.25 14.5853 0.25 15C0.25 15.4148 0.586 15.75 1 15.75H4C4 16.5773 4.67275 17.25 5.5 17.25H7C7.82725 17.25 8.5 16.5773 8.5 15.75H19C19.414 15.75 19.75 15.4148 19.75 15C19.75 14.5853 19.414 14.25 19 14.25ZM5.5 15.75V14.25H7L7.00075 14.997C7.00075 14.9985 7 14.9992 7 15C7 15.0008 7.00075 15.0015 7.00075 15.003V15.75H5.5Z"
                              fill="#2E2E2E"
                           />
                        </svg>
                     </Link>
                     <Dropdown className="custom-dropdown mb-2">
                        <Dropdown.Toggle
                           variant=""
                           className="arrow-false btn bg-white d-flex align-items-center"
                        >
                           <div className="text-left mr-3">
                              <span className="text-black">Newest</span>
                           </div>
                           <i className="fa fa-caret-down" aria-hidden="true" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                           <Dropdown.Item className="" to="/campaign">
                              4 June 2020 - 4 July 2020
                           </Dropdown.Item>
                           <Dropdown.Item className="" to="/campaign">
                              5 july 2020 - 4 Aug 2020
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </div>
               </div>
            </div> */}
            {trucks.map((item, index) => {
                return(
                    <div key={index} className="campaign-audio col-md-12 loop loop-truck">
                        <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                            <div className="my-2 col-xl-4 col-xxl-5 col-lg-6 col-md-8 col-sm-12">
                                <div className="media align-items-center">
                                    <div className="d-inline-block position-relative donut-chart-sale mr-4">
                                    <ChartDoughnut2
                                        backgroundColor="#E15F65"
                                        height="90"
                                        width="90"
                                        value="63"
                                    />
                                    <small>63%</small>
                                    </div>
                                    <div className="media-body">
                                    <p className="text-danger mb-0">{item.ID}</p>
                                    <h3 className="fs-20 mb-0">
                                        <Link className="text-black" to={'/trucks/' + item.ID}>
                                            {item.name}
                                        </Link>
                                    </h3>
                                    <svg
                                        width={17}
                                        height={14}
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15 2L5 12.01L2 9.01"
                                            stroke="#737373"
                                            strokeWidth={3}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span className="fs-14 ml-1">
                                        פורסם ב {created}                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-xxl-3 col-lg-6 col-md-4 col-sm-8 px-3">
                                <div className="row align-items-center my-2">
                                    <div className="col-6">
                                    <h3 className="fs-20 text-black mb-0">153</h3>
                                    <span className="fs-14">חשיפות החודש</span>
                                    </div>
                                    <div className="col-6">
                                    <h3 className="fs-20 text-black mb-0">2593</h3>
                                    <span className="fs-14">חשיפות השנה</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                                <a
                                    className="btn btn-outline-dark p-3 text-ov"
                                    target="_blank"
                                    href="https://www.foodtrack.co.il/get-truck/?id=41"
                                >
                                    <svg
                                    className="mr-2 scale5"
                                    width={14}
                                    height={14}
                                    viewBox="0 0 18 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M12.1583 3.8476H1.93099C0.971333 3.8476 0.191895 4.62703 0.191895 5.58669V20.2608C0.191895 21.2205 0.971333 21.9999 1.93099 21.9999H12.1583C13.118 21.9999 13.8974 21.2205 13.8974 20.2608V5.58669C13.8929 4.62703 13.1135 3.8476 12.1583 3.8476ZM12.6764 20.2563C12.6764 20.5447 12.4422 20.779 12.1538 20.779H1.92648C1.63814 20.779 1.40385 20.5447 1.40385 20.2563V5.58669C1.40385 5.29834 1.63814 5.06406 1.92648 5.06406H12.1538C12.4422 5.06406 12.6764 5.29834 12.6764 5.58669V20.2563Z"
                                        fill="#8E8E8E"
                                    />
                                    <path
                                        d="M16.069 0H5.84163C4.88198 0 4.10254 0.779439 4.10254 1.73909C4.10254 2.077 4.37286 2.34733 4.71077 2.34733C5.04868 2.34733 5.319 2.077 5.319 1.73909C5.319 1.45075 5.55329 1.21647 5.84163 1.21647H16.069C16.3573 1.21647 16.5916 1.45075 16.5916 1.73909V16.4133C16.5916 16.7016 16.3573 16.9359 16.069 16.9359C15.731 16.9359 15.4607 17.2062 15.4607 17.5441C15.4607 17.882 15.731 18.1524 16.069 18.1524C17.0286 18.1524 17.808 17.3729 17.808 16.4133V1.73909C17.808 0.779439 17.0286 0 16.069 0Z"
                                        fill="#8E8E8E"
                                    />
                                    </svg>
                                    https://www.foodtrack.co.il/get-truck/?id=41
                                </a>
                            </div>
                        </div>
                        </div>
                );
            })}
         </div>
      </>
   );
}

export default Trucks;
