import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import EventCalendar from "./EventCalendar";

import PageTitle from "../../layouts/PageTitle";
const Events = () => {
    const [events, setEvents] = useState([]);

    const getEvents = async () => {
        let response = await fetch('https://api.foodtrack.co.il/api/events', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },
        });

        if (response.ok) {
            let events = await response.json();

            if( events.length > 0 ){
                let customizedEventsArray = [];
                for (let i = 0; i < events.length; i++) {
                    customizedEventsArray.push({
                        id: events[i].id,
                        title: events[i].title,
                        start: new Date(events[i].start_date),
                        end_date: new Date(events[i].end_date),
                        start_time: events[i].start_time,
                        end_time: events[i].end_time,
                        location: events[i].location,
                        coords: events[i].coords,
                        publisher: events[i].publisher,
                        host: events[i].host,
                        created: new Date(events[i].created)
                    });
                    setEvents(customizedEventsArray);
                }
                
            }
        } else {
            alert("HTTP-Error: " + response.status);
        }
   }

   useEffect(() => {
        getEvents();
    }, []);
   return (
      <div className="h-80">
            <div className="row">
                <div className="col">
                    <h1>אירועים</h1>
            
                    <PageTitle activeMenu="אירועים" motherMenu="ראשי" />
                </div>
                <div className="col">
                    <Link to={'/new-event'} className="btn btn-outline-dark mt-5 pull-left">אירוע חדש</Link>
                </div>
            </div>
            <div className="animated fadeIn">
            <Row>
                <Col lg={3}>
                <div className="card">
                        <div className="card-header pb-0 border-bottom-0">
                        <h4 className="card-title">אירועים קרובים</h4>
                        </div>
                        <div className="card-body">
                        <div id="external-events">
                            {events.map((event) => {
                                // const startDate = new Date(event.start_date);
                                let d = new Date(event.start);
                                let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                                let mo = new Intl.DateTimeFormat('he', { month: 'long' }).format(d);
                                let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                                return(
                                    <Link to={'/events/' + event.id}
                                        className=" btn-block mx-0 text-left mt-0 btn btn-outline-dark mb-2"
                                        title={event.title}
                                        key={event.id}
                                    >
                                        <h5 className="mb-0">{event.title}</h5>
                                        <p className="mb-0">{`${da} ב${mo}, ${ye}`}</p>
                                    </Link>
                                );
                            })}
                        </div>
                        </div>
                    </div>
                </Col>
                <Col lg={9}>
                    <EventCalendar events={events} />
                </Col>
            </Row>
            
            </div>
            </div>
   );
};

export default Events;
